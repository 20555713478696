import { useQuery } from '@apollo/client';
// Types
import {
  Gender,
  MemorabiliaFilterInput,
  MemorabiliaOrderBy,
  MemorabiliaProductType,
  MemorabiliaStatus,
  MerchProductOrderBy,
} from 'api/graphql-global-types';
import { GetAffiliateStreamProducts_getAffiliateStreamProducts } from 'api/streams/types/GetAffiliateStreamProducts';
import {
  GetMerchMinimalInfo,
  GetMerchMinimalInfoVariables,
} from 'api/merch/types/GetMerchMinimalInfo';
// Api
import { GET_MERCH_MINIMAL_INFO } from 'api/merch/queries';
// Hooks
import { useGetAthleteMemorabilia } from 'hooks';
// Ui
import Loader from 'ui3/Loader/Loader';
import Text from 'ui3/Text/Text';
// Components
import MerchListItem from 'components/common3/MerchList/components/MerchListItem/MerchListItem';
import MemorabiliaListItem from 'components/common3/MemorabiliaList/components/MemorabiliaListItem';
import CarouselSlider from 'components/common3/CarouselSlider/CarouselSlider';
import AffiliateProductItem from 'components/common2/AffiliateProductItem/AffiliateProductItem';
// Styles
import styles from './RelatedProfileProductsList.module.scss';

export const MEMORABILIA_VARIABLES: MemorabiliaFilterInput = {
  statuses: [MemorabiliaStatus.Active],
  limit: 8,
  isOrphanPage: null,
  orderBy: MemorabiliaOrderBy.price,
};

type RelatedProfileProductsListProps = {
  storeId: string;
  title?: string;
  backgroundTitle?: string;
  className?: string;
  shouldHideMostExpensiveItem?: boolean;
  isProductDetailsPage?: boolean;
  currentMemorabiliaId?: string;
  affiliateProducts?: GetAffiliateStreamProducts_getAffiliateStreamProducts[];
  affiliateProductsLoading?: boolean;
  showOnlyMerch?: boolean;
  showOnlyMemorabilia?: boolean;
  showOnlyProducts?: boolean;
};

const RelatedProfileProductsList = ({
  storeId,
  title,
  className,
  shouldHideMostExpensiveItem = false,
  currentMemorabiliaId,
  isProductDetailsPage,
  affiliateProducts = [],
  affiliateProductsLoading = false,
  showOnlyMerch = false,
  showOnlyMemorabilia = false,
  showOnlyProducts = false,
}: RelatedProfileProductsListProps) => {
  if (showOnlyMemorabilia) {
    MEMORABILIA_VARIABLES.productTypes = [MemorabiliaProductType.Memorabilia];
  }
  if (showOnlyProducts) {
    MEMORABILIA_VARIABLES.productTypes = [MemorabiliaProductType.Product];
  }
  if (showOnlyMemorabilia && showOnlyProducts) {
    MEMORABILIA_VARIABLES.productTypes = [
      MemorabiliaProductType.Memorabilia,
      MemorabiliaProductType.Product,
    ];
  }
  // using useQuery instead of hook to avoid athleteSlug skip for pages without athlete slug
  const { data: merchData, loading: merchLoading } = useQuery<
    GetMerchMinimalInfo,
    GetMerchMinimalInfoVariables
  >(GET_MERCH_MINIMAL_INFO, {
    variables: {
      input: {
        orderBy: MerchProductOrderBy.Date,
        storeIds: [storeId],
        gender: [Gender.Male, Gender.Female],
        limit: 8,
      },
    },
  });

  const {
    data: memorabiliaData,
    loading: memorabiliaLoading,
  } = useGetAthleteMemorabilia({
    storeId,
    variables: MEMORABILIA_VARIABLES,
  });

  const merch = merchData?.getMerchProducts.entities || [];

  const mostExpensiveItemId = memorabiliaData?.getMemorabilia?.entities
    ?.filter(
      ({ memorabiliaProductType }) =>
        memorabiliaProductType === MemorabiliaProductType.Memorabilia
    )
    .sort((a, b) => (b?.price || 0) - (a?.price || 0))[0]?.id;

  const athleteProfileMemorabilia =
    memorabiliaData?.getMemorabilia.entities?.filter(
      (item) =>
        item.memorabiliaProductType === MemorabiliaProductType.Memorabilia &&
        item.id !== mostExpensiveItemId
    ) || [];

  const memorabiliaDetailsPageData =
    memorabiliaData?.getMemorabilia.entities?.filter(
      (item) =>
        item.memorabiliaProductType === MemorabiliaProductType.Memorabilia &&
        item.id !== currentMemorabiliaId
    ) || [];

  const memorabilia =
    showOnlyMemorabilia || showOnlyProducts
      ? memorabiliaData?.getMemorabilia.entities
      : isProductDetailsPage
      ? memorabiliaDetailsPageData
      : shouldHideMostExpensiveItem
      ? athleteProfileMemorabilia
      : memorabiliaData?.getMemorabilia.entities?.filter(
          (item) =>
            item.memorabiliaProductType === MemorabiliaProductType.Memorabilia
        ) || [];

  const products =
    memorabiliaData?.getMemorabilia?.entities?.filter(
      ({ id, memorabiliaProductType }) => {
        if (isProductDetailsPage) {
          return (
            memorabiliaProductType === MemorabiliaProductType.Product &&
            id !== currentMemorabiliaId
          );
        }

        return memorabiliaProductType === MemorabiliaProductType.Product;
      }
    ) || [];

  const isEmptyList = Boolean(
    !merchLoading &&
      merchData &&
      !merch.length &&
      !memorabiliaLoading &&
      !affiliateProductsLoading &&
      memorabiliaData &&
      !affiliateProducts.length &&
      !memorabilia?.length &&
      !products.length
  );

  const memorabiliaLength: number = (memorabilia && memorabilia.length) || 0;

  const showInfiniteCarousel = showOnlyMerch
    ? merch.length > 4
    : showOnlyMemorabilia || showOnlyProducts
    ? memorabiliaLength > 4
    : merch.length + memorabiliaLength + products.length > 4;

  const renderMerch = () => (
    <div className={styles.rootCarousel}>
      <CarouselSlider settings={{ infinite: showInfiniteCarousel }}>
        {affiliateProducts.map((item) => (
          <AffiliateProductItem key={item.id} affiliateProduct={item} />
        ))}
        {merch.map((merch) => (
          <MerchListItem
            key={merch.id}
            merch={merch}
            openInNewTab
            imageLazyLoading
            type="default"
          />
        ))}
      </CarouselSlider>
    </div>
  );

  const renderMemorabilia = () => {
    return (
      <div className={styles.rootCarousel}>
        <CarouselSlider settings={{ infinite: showInfiniteCarousel }}>
          {affiliateProducts.map((item) => (
            <AffiliateProductItem key={item.id} affiliateProduct={item} />
          ))}
          {memorabilia?.map((item) => (
            <MemorabiliaListItem
              key={item.id}
              memorabilia={item}
              itemType="default"
              openInNewTab
              imageLazyLoading
            />
          ))}
        </CarouselSlider>
      </div>
    );
  };

  // NOTE: renderAll without Experience, as it's a separate class of merchandise
  const renderAll = () => {
    return (
      <div className={styles.rootCarousel}>
        <CarouselSlider settings={{ infinite: showInfiniteCarousel }}>
          {affiliateProducts.map((item) => (
            <AffiliateProductItem key={item.id} affiliateProduct={item} />
          ))}
          {memorabilia?.map((memorabilia) => (
            <MemorabiliaListItem
              key={memorabilia.id}
              memorabilia={memorabilia}
              itemType="default"
              openInNewTab
              imageLazyLoading
            />
          ))}
          {products.map((product) => (
            <MemorabiliaListItem
              key={product.id}
              memorabilia={product}
              itemType="default"
              openInNewTab
              imageLazyLoading
            />
          ))}
          {merch.map((merch) => (
            <MerchListItem
              key={merch.id}
              merch={merch}
              openInNewTab
              imageLazyLoading
              type="default"
            />
          ))}
        </CarouselSlider>
      </div>
    );
  };

  const renderCarousel = () => {
    if (showOnlyMemorabilia || showOnlyProducts) {
      return renderMemorabilia();
    }
    if (showOnlyMerch) {
      return renderMerch();
    }
    if (!showOnlyMerch && !showOnlyMemorabilia) {
      return renderAll();
    }
  };

  if (isEmptyList) {
    return null;
  }

  return (
    <div className={className}>
      <Loader
        loading={merchLoading || memorabiliaLoading || affiliateProductsLoading}
        size="large"
      />
      {title && (
        <Text variant="h2" className={styles.title}>
          {title}
        </Text>
      )}
      {renderCarousel()}
    </div>
  );
};

export default RelatedProfileProductsList;
