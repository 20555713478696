import React from 'react';
import { isNumber } from 'lodash';
// Types
import { BaseCardProps } from './BaseCard/BaseCard';
import {
  MemorabiliaProductType,
  ProductTypesType,
} from 'api/graphql-global-types';
// Components
import BaseCard from './BaseCard/BaseCard';
import CardLabel, { LabelVariant } from './CardLabel/CardLabel';

export enum NewProductTypes {
  PERSONAL_VIDEO = 'personalVideo',
}

export type ProductType =
  | MemorabiliaProductType
  | Exclude<ProductTypesType, 'Ama' | 'Shoutout' | 'Stream'>
  | NewProductTypes;

interface ProductCardProps extends BaseCardProps {
  type: ProductType;
  price?: string;
  count?: number;
  isAuction?: boolean | null;
  hideCategory?: boolean;
  customLabels?: string[];
  customLabelsPosition?: 'top' | 'bottom';
  customLabelsVariant?: LabelVariant;
}

const TopLabels: React.FC<Partial<ProductCardProps>> = ({
  type,
  count,
  size,
  hideCategory,
}) => {
  const hasCount = isNumber(count);
  const countProps =
    hasCount && count > 0
      ? type === MemorabiliaProductType.Memorabilia
        ? { variant: 'accent' as LabelVariant, text: `${count} Available` }
        : {
            variant: 'inStock' as LabelVariant,
            text: `${count} Available`,
          }
      : {
          variant: 'outOfStock' as LabelVariant,
          text: 'Out of stock',
        };

  return (
    <>
      {hideCategory ? null : (
        <CardLabel text={type} size={size} variant="secondary" />
      )}
      {hasCount ? <CardLabel size={size} {...countProps} /> : null}
    </>
  );
};

const createCustomLabels: React.FC<Partial<ProductCardProps>> = ({
  customLabels,
  customLabelsVariant = 'secondary',
  size,
}) => {
  return (
    <>
      {customLabels?.map((label, index) => {
        return (
          <CardLabel
            text={label}
            size={size}
            key={index}
            variant={customLabelsVariant}
          />
        );
      })}
    </>
  );
};

const BottomLabels: React.FC<Partial<ProductCardProps>> = ({
  price,
  size,
  isAuction,
}) => {
  return (
    <>
      {price ? <CardLabel text={price} size={size} isCurrency /> : null}
      {isAuction ? (
        <CardLabel text="Auction" size={size} variant="accent" />
      ) : null}
    </>
  );
};

const ProductCard: React.FC<ProductCardProps> = ({
  type,
  size,
  title,
  user,
  count,
  price,
  imageUrl,
  linkUrl,
  userUrl,
  titleUrl,
  isAuction = false,
  hideCategory = false,
  width,
  height,
  priorityImageLoading,
  customLabels,
  customLabelsPosition,
  customLabelsVariant,
  userLineClamp = 2,
  className,
  descriptionClassName,
}) => {
  const topLabels =
    customLabelsPosition === 'top'
      ? createCustomLabels({ customLabels, customLabelsVariant, size })
      : TopLabels({ type, count, hideCategory });
  const bottomLabels =
    customLabelsPosition === 'bottom'
      ? createCustomLabels({ customLabels, customLabelsVariant, size })
      : BottomLabels({ price, isAuction });

  return (
    <BaseCard
      className={className}
      descriptionClassName={descriptionClassName}
      imageUrl={imageUrl}
      userUrl={userUrl}
      title={title}
      user={user}
      size={size}
      linkUrl={linkUrl}
      titleUrl={titleUrl}
      topLabels={topLabels}
      bottomLabels={bottomLabels}
      height={height}
      width={width}
      priorityImageLoading={priorityImageLoading}
      isProductCard={!customLabels}
      userLineClamp={userLineClamp}
    />
  );
};

export default ProductCard;
