import cn from 'classnames';
// Types
import { GetMemorabilia_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabilia';
import { GetMemorabiliaMinimalInfo_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabiliaMinimalInfo';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
// Constants
import { ATHLETES } from 'constants/routes';
// Ui
import ProductCard from 'ui3/Card/ProductCard';
// Styles
import styles from './MemorabiliaListItem.module.scss';

export type TypeListItem = 'default' | 'ad' | 'related';

type MemorabiliaListItemProps = {
  memorabilia:
    | GetMemorabilia_getMemorabilia_entities
    | GetMemorabiliaMinimalInfo_getMemorabilia_entities;
  imageLazyLoading?: boolean;
  openInNewTab?: boolean;
  itemType: TypeListItem;
  className?: string;
};

const MemorabiliaListItem = ({
  memorabilia,
  itemType,
  imageLazyLoading,
  className,
}: MemorabiliaListItemProps) => {
  const isDefault = itemType === 'default';
  const isAd = itemType === 'ad';
  const isRelated = itemType === 'related';

  const price = formatCurrencyString(memorabilia.price);
  const link = `/${memorabilia?.store?.slug}/memorabilia/${memorabilia.slug}`;
  const storeName = memorabilia?.store?.storeDetails?.storeName || '';
  const mainImage = memorabilia?.images?.find((item) => item.isMainImage);

  return (
    <ProductCard
      className={cn(styles.root, className, {
        [styles.default]: isDefault,
        [styles.ad]: isAd,
        [styles.related]: isRelated,
      })}
      descriptionClassName={styles.infoWrapper}
      type={memorabilia.memorabiliaProductType}
      hideCategory
      title={memorabilia.title}
      count={memorabilia?.numberOfUnits}
      user={storeName}
      price={price}
      linkUrl={link}
      imageUrl={mainImage?.url ?? ''}
      isAuction={
        memorabilia && 'isForAuction' in memorabilia
          ? memorabilia.isForAuction
          : false
      }
      userUrl={
        memorabilia.store?.slug ? `/${memorabilia.store?.slug}` : ATHLETES
      }
      priorityImageLoading={!imageLazyLoading}
      userLineClamp={1}
    />
  );
};

export default MemorabiliaListItem;
