import cn from 'classnames';
// Types
import { GetAllMerchProducts_getMerchProducts_entities } from 'api/merch/types/GetAllMerchProducts';
import { GetMerchMinimalInfo_getMerchProducts_entities } from 'api/merch/types/GetMerchMinimalInfo';
import { ProductTypesType } from 'api/graphql-global-types';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
import { computeMerchProductPath } from 'helpers/routes';
import { getStreamerName } from 'helpers/streams';
// Constants
import { ATHLETES } from 'constants/routes';
// Ui
import ProductCard from 'ui3/Card/ProductCard';
// Styles
import styles from './MerchListItem.module.scss';

// "default" type for Home & List pages,
// "ad" - for Stream page
// "related" - for Store & Merch pages,
export type TypeMerchListItem = 'default' | 'ad' | 'related';

type MerchListItemProps = {
  merch:
    | GetAllMerchProducts_getMerchProducts_entities
    | GetMerchMinimalInfo_getMerchProducts_entities;
  imageLazyLoading?: boolean;
  openInNewTab?: boolean;
  type: TypeMerchListItem;
  className?: string;
};

const MerchListItem = ({
  merch,
  type,
  imageLazyLoading,
  className,
}: MerchListItemProps) => {
  const isDefault = type === 'default';
  const isAd = type === 'ad';
  const isRelated = type === 'related';
  const image = merch.mainImageUrl || '';
  const price = formatCurrencyString(merch.minPrice);
  const link = computeMerchProductPath(merch);
  const storeName = getStreamerName(merch?.store);

  return (
    <ProductCard
      className={cn(styles.root, className, {
        [styles.default]: isDefault,
        [styles.ad]: isAd,
        [styles.related]: isRelated,
      })}
      descriptionClassName={styles.infoWrapper}
      type={ProductTypesType.Merch}
      hideCategory
      title={merch.title}
      user={storeName}
      price={price}
      linkUrl={link}
      imageUrl={image}
      userUrl={merch.store?.slug ? `/${merch.store?.slug}` : ATHLETES}
      priorityImageLoading={!imageLazyLoading}
      userLineClamp={1}
    />
  );
};

export default MerchListItem;
