// Types
import { Settings } from 'react-slick';
// Ui
import Carousel from 'ui3/Carousel/Carousel';
// Styles
import styles from './CarouselSlider.module.scss';

const sliderSettings = {
  infinite: true,
  adaptiveHeight: false,
  speed: 500,
  swipeToSlide: true,
  variableWidth: true,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
} as Settings;

type CarouselSliderProps = {
  settings?: Settings;
};

const CarouselSlider: React.FC<CarouselSliderProps> = ({
  children,
  settings,
}) => {
  return (
    // TODO fix when dragging horizontally, disable event to click on children
    <div className={styles.root}>
      <Carousel {...sliderSettings} {...settings} className={styles.slider}>
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselSlider;
